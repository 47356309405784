import { BulkOperation } from "o365-utils";

interface IStorageExpiration {
    expirationDays: number;
    expirationDate: Date;
}

class StorageExpiration {
    private _store: Storage;
    private _expiration: Map<string, IStorageExpiration> = new Map();
    private _broadcastChannel: BroadcastChannel;

    private _defaultExpirationDays = 30;

    private _storageKeyName = 'expirationDates';
    private _expirationDateKey = 'expirationDate';
    private _broadCastChannelPostfix = 'ExpirationChannel';


    private __bulkAdd?: BulkOperation<{ expirationDays: number | undefined, keys: string[] }, void>;
    private get _bulkAdd() {
        if (this.__bulkAdd == null) {
            this.__bulkAdd = new BulkOperation<{ expirationDays: number | undefined, keys: string[] }, void>({
                bulkOperation: (pQueue) => {
                    for (const item of pQueue) {
                        for (const key of item.value.keys) {
                            this._setExpiration(item.value.expirationDays || this._defaultExpirationDays, key);
                        }
                    }

                    this._storeExpiration();
                    for (const item of pQueue) {
                        item.res();
                    }
                    return Promise.resolve();
                },
                delay: 200
            });
        }
        return this.__bulkAdd;
    }

    private __bulkDelete?: BulkOperation<{ keys: string[] }, void>;
    private get _bulkDelete() {
        if (this.__bulkDelete == null) {
            this.__bulkDelete = new BulkOperation<{ keys: string[] }, void>({
                bulkOperation: (pQueue) => {
                    for (const item of pQueue) {
                        for (const key of item.value.keys) {
                            this._expiration.delete(key)
                        }
                    }

                    this._storeExpiration();
                    for (const item of pQueue) {
                        item.res();
                    }
                    return Promise.resolve();
                },
                delay: 200
            });
        }
        return this.__bulkDelete;
    }

    private __bulkUpdate?: BulkOperation<{ keys: string[] }, void>;
    private get _bulkUpdate() {
        if (this.__bulkUpdate == null) {
            this.__bulkUpdate = new BulkOperation<{ keys: string[] }, void>({
                bulkOperation: (pQueue) => {
                    for (const item of pQueue) {
                        for (const key of item.value.keys) {
                            const { expirationDays } = this._expiration.get(key) || {};
                            if (expirationDays) {
                                this._setExpiration(expirationDays, key);
                            }
                        }
                    }

                    this._storeExpiration();
                    for (const item of pQueue) {
                        item.res();
                    }
                    return Promise.resolve();
                },
                delay: 200
            });
        }
        return this.__bulkUpdate;
    }


    private _removeExpiredItemsDebouncer?: number;

    constructor(pStorage: Storage) {
        this._store = pStorage;

        // Turn date strings back into Date objects
        const reviver = (key: string, value: string): string | Date | null => {
            if (key !== this._expirationDateKey) return value;

            const date = new Date(value);
            return isNaN(date.valueOf()) ? null : date;
        };

        const expiration = this._store.getItem(this._storageKeyName) ?? '[]';

        try {
            const revivedJSON: IStorageExpiration[] = JSON.parse(
                expiration,
                reviver
            );
            this._expiration = new Map(Object.entries(revivedJSON));
        } catch (error) {
            console.error('Error parsing JSON in constuctor:', error);
        }

        const broadCastChannelName = `${this._store === window.localStorage
            ? 'localStorage'
            : 'sessionStorage'
            }${this._broadCastChannelPostfix}`;

        this._broadcastChannel = new BroadcastChannel(broadCastChannelName);

        this._broadcastChannel.onmessage = (event) => {
            try {
                this._expiration = event.data;
            } catch (error) {
                console.error('Error in broadcast message data:', error);
            }
        };
    }

    add(pExpirationDays: number | undefined, ...pKeys: string[]) {
        try {
            return this._bulkAdd.addToQueue({
                expirationDays: pExpirationDays,
                keys: pKeys
            });
        } catch (ex) {
            console.warn(ex);
            return Promise.resolve();
        }
    }

    delete(...pKeys: string[]) {
        try {
            return this._bulkDelete.addToQueue({
                keys: pKeys
            });
        } catch (ex) {
            console.warn(ex);
            return Promise.resolve();
        }
    }

    update(...pKeys: string[]) {
        try {
            return this._bulkUpdate.addToQueue({
                keys: pKeys
            });
        } catch (ex) {
            console.warn(ex)
            return Promise.resolve();
        }
    }

    removeExpiredItems() {
        try {
            if (this._removeExpiredItemsDebouncer) {
                clearTimeout(this._removeExpiredItemsDebouncer);
            }
            this._removeExpiredItemsDebouncer = setTimeout(() => {
                const currentDate = new Date();

                const expiredItems = [...this._expiration].flatMap(
                    ([key, { expirationDate }]) =>
                        currentDate > expirationDate ? key : []
                );
                for (const expiredItem of expiredItems) {
                    this._store.removeItem(expiredItem)
                }

                this.delete(...expiredItems);

                this._removeExpiredItemsDebouncer = undefined;
            }, 200);
        } catch (ex) {
            console.warn(ex);
        }
    }

    private _setExpiration(expirationDays: number, key: string) {
        const date = new Date();
        const expirationDate = new Date(
            date.setDate(date.getDate() + expirationDays)
        );

        this._expiration.set(key, { expirationDays, expirationDate });
    }

    private _storeExpiration() {
        this._store.setItem(
            this._storageKeyName,
            JSON.stringify(Object.fromEntries(this._expiration))
        );

        this._broadcastChannel.postMessage(this._expiration);
    }
}

let localStorageExpiration: StorageExpiration | undefined = undefined;
let sessionStorageExpiration: StorageExpiration | undefined = undefined;

/**
 * Get the experation singleton for storage type
 * @param pType - type of the storage
 */
export function getStorageExparation(pType: 'sessionStorage' | 'localStorage') {
    if (pType == 'sessionStorage') {
        if (sessionStorageExpiration == null) {
            sessionStorageExpiration = new StorageExpiration(sessionStorage);
        }
        return sessionStorageExpiration;
    } else {
        if (localStorageExpiration == null) {
            localStorageExpiration = new StorageExpiration(localStorage);
        }
        return localStorageExpiration;
    }
}

export default StorageExpiration;
export type { IStorageExpiration };
